import Layout from "../components/layout"
import { graphql } from "gatsby"
import React from "react"
import { withTrans } from "../withTrans"
import HighlightsComponent from "../components/HighlightsComponent"
import HeroComponent from "../components/HeroComponent"
import { Tab } from "@headlessui/react"
import CardsList from "../components/CardsList"

export const query = graphql`
  query($locale: [GRAPHCMS_Locale!]!) {
    gcms {
      archivePages(locales: $locale) {
        title
        description
        sliderImages(locales: en) {
          url
          mimeType
        }
      }
      archiveHighlights(locales: $locale) {
        title
        description
        cardImage(locales: en) {
          url
        }
        slug
      }
      archiveTutorials(locales: $locale) {
        title
        description
        cardImage(locales: en) {
          url
        }
        slug
      }
      ourCollections(locales: $locale) {
        title
        description
        cardImage(locales: en) {
          url
        }
        slug
      }
      servicesCards(locales: $locale, where: { spaceService: Archive }) {
        title
        description
        id
        cardImage(locales: en) {
          url
        }
        slug
        spaceService
        typeOfService
      }
    }
  }
`

class Archive extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      archivePageData: this.props.data.gcms.archivePages[0],
      archiveHighlightsData: this.props.data.gcms.archiveHighlights,
      archiveTutorialsData: this.props.data.gcms.archiveTutorials,
      ourCollectionsData: this.props.data.gcms.ourCollections,
      archiveServicesCards: this.props.data.gcms.servicesCards,
    }
    this.state.archiveServicesCards.forEach(function(element, index, array) {
      array[index].slug = "../services/"+element.slug;
    });
  }

  render() {
    const { t } = this.props

    return (
      <Layout>
        <Tab.Group
          defaultIndex={0}
        >
          <Tab.List className="font-futurastd_book text-center lg:grid lg:grid-rows-1 lg:grid-cols-5 lg:justify-evenly lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-center mb-3 mx-3 lg:mx-7">
            <Tab className="col-span-4 lg:col-span-1 row-span-1 uppercase w-full lg:w-auto lg:order-4">
              <div className="text-center inline-block bg-white">
                <span className="text-2xl">&lt;</span>
                <span className="tracking-title-3 text-base ml-3 mr-2">
                  {t("navbar_title_archive")}
                </span>
                <span className="text-2xl">&gt;</span>
              </div>
            </Tab>

            <Tab
              className={({ selected }) =>
                selected
                  ? " font-futurastd_bold lg:order-first"
                  : "lg:order-first"
              }
            >
              <div className="bg-white lg:bg-title-double-slash lg:bg-left lg:bg-no-repeat lg:pl-8 text-label-fs-10 uppercase tracking-title-2 lg:inline-block leading-7 text-center">
                <div>{t("label_services")}</div>
              </div>
            </Tab>

            <div className="lg:hidden separator inline-block align-middle mx-1"></div>

            <Tab
              className={({ selected }) =>
                selected ? " font-futurastd_bold lg:order-2" : "lg:order-2"
              }
            >
              <div className="bg-white lg:bg-title-double-slash lg:bg-left lg:bg-no-repeat lg:pl-8 text-label-fs-10 uppercase tracking-title-2 lg:inline-block leading-7 text-center">
                <div className="">{t("label_tutorials")}</div>
              </div>
            </Tab>

            <div className="lg:hidden separator inline-block align-middle mx-1"></div>

            <Tab
              className={({ selected }) =>
                selected ? " font-futurastd_bold lg:order-5" : "lg:order-5"
              }
            >
              <div className="bg-white lg:bg-title-double-slash lg:bg-left lg:bg-no-repeat lg:pl-8 text-label-fs-10 uppercase tracking-title-2 lg:inline-block leading-7 text-center">
                <div className="">{t("label_ourcollection")}</div>
              </div>
            </Tab>

            <div className="lg:hidden separator inline-block align-middle mx-1"></div>

            <Tab
              className={({ selected }) =>
                selected ? " font-futurastd_bold lg:order-6" : "lg:order-6"
              }
            >
              <div className="bg-white lg:bg-title-double-slash lg:bg-left lg:bg-no-repeat lg:pl-8 text-label-fs-10 uppercase tracking-title-2 lg:inline-block leading-7 text-center">
                <div className="">{t("label_archive_highlights")}</div>
              </div>
            </Tab>
          </Tab.List>

          <Tab.Panels>
            <Tab.Panel className="">
              <HeroComponent
                sliderItems={this.state.archivePageData.sliderImages}
                pageData={this.state.archivePageData}
              />

              {/* Highlights Section */}
              <HighlightsComponent
                title={t("title_archive_highlights")}
                highlights={this.state.archiveHighlightsData}
              />
            </Tab.Panel>
            <Tab.Panel className="lg:order-1">
              <CardsList items={this.state.archiveServicesCards} />
            </Tab.Panel>
            <Tab.Panel className="lg:order-2">
              <CardsList items={this.state.archiveTutorialsData} />
            </Tab.Panel>
            <Tab.Panel>
              <CardsList items={this.state.ourCollectionsData} />
            </Tab.Panel>
            <Tab.Panel>
              <CardsList items={this.state.archiveHighlightsData} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </Layout>
    )
  }
}

export default withTrans(Archive)
